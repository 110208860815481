import React, { useState, useRef, useContext, useEffect } from 'react';
import ScreenLoader from '../ui/Loader/Loader.js';
import axios from 'axios';
import { ToastContainer, toast } from "react-toastify"; 
import { FaDownload } from "react-icons/fa";
import { FaEdit } from "react-icons/fa";  
import { FiRefreshCw } from "react-icons/fi";

import ReactMarkdown from "react-markdown";
import { detectPlatform } from '../ui/Platform/Platform.js';
import { demoURL, Node_API_URL } from "../../client";
import { useSelector,useDispatch } from "react-redux"; 
import { logout } from "../../features/auth/authSlice.js"; 
import { SoapNotesPrompt } from "../prompts/soapNotes"; 
import Loader from "../ui/Loader/ButtonLoader";   
import { InitialExamintionPrompt } from "../prompts/InitialExamintion";
import { FollowUpPrompt } from "../prompts/FollowUp";  
import ReportGenerator from '../OpenAiReport/ReportGenerator.js';
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { materialLight } from "react-syntax-highlighter/dist/esm/styles/prism";
import { MdDeleteForever } from "react-icons/md";  
import { useParams } from 'react-router-dom';
import { GlobalStateContext } from '../../Context/GlobalStateContext.js';
import Mic from '../ui/Mic/Mic.js';
const Recordaudio = ({setSwitch , setReportdata}) => {
  const [platform, setPlatform] = useState('Web');
  const {navigate,user,setUser,checker,setRationale,setOriginal,setIcdCodes,setSubjective, setObjective, setmed,setCptCodes, setDxCodes, setAssessment, setPlan, setSoapNotesSummary, setAllergy, setHPI, setPMH, setROS, setchiefComplaint,setphysicalExamination ,token,showToast,userTimezone,toastActive} = useContext(GlobalStateContext)
  const { patientId } = useParams();  
    const [data,setData] = useState("")  
    const { userInfo } = useSelector((state) => state.auth); 
  const [isModalOpen, setIsModalOpen] = useState(false);  
  const [text,setText] = useState(''); 
   const [isOpen , setOpen] = useState(false); 
     const [changeText,setchangeText] = useState('');
   const [promptText, setPromptText] = useState(SoapNotesPrompt);
   const [selectedModel, setSelectedModel] = useState("gpt-4o");
  const [showText,setShowText]=useState(true)
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false);
const [dateNow, setDate] = useState(null); 
const [tra,setTra] = useState('');


const saveReport = async()=>{
  try{
    setLoading(true)      
   const Bodydata= {
          "pId":patientId,
          "reportData":data,
          "transcription":tra,
          "mode":"generate"
      }  
    const config = {
      headers: {
          "Authorization": `Bearer ${token}`
      }
  }
    const res  = await axios.post(`${Node_API_URL}/api/post/newReportMethodStoredIntoDb`,Bodydata,config)

    if(res.data.response === true)
    {
      showToast('Report generated')
      navigate(`/Report2.0/${res.data.id}`)

    }
  }catch(e)
  {
      showToast("Error in saving report try again")
  }finally{
    setLoading(false)
  }
}



  useEffect(() => {
    const today = new Date();
    const formattedDate = today.toLocaleDateString('en-GB', {
      timeZone: userTimezone,
    });
    setDate(formattedDate);
  }, []);
  useEffect(()=>{
    localStorage.removeItem("visit-id")
    checker().then((res)=>{
      if(res==false)
      {
      setUser(false)
      dispatch(logout())
      navigate('/')
      }
    })
    
  },[checker,user])
  useEffect(()=>{
    const OS = detectPlatform();
    setPlatform(OS)
  },[])





  const getCurrentDateTime = () => {
    let currentDate = new Date();
    let day = currentDate.getDate();
    let month = currentDate.getMonth() + 1;
    let year = currentDate.getFullYear();
    let hours = currentDate.getHours();
    let minutes = currentDate.getMinutes();
    let seconds = currentDate.getSeconds();
    let ampm = hours >= 12 ? 'PM' : 'AM';

    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    seconds = seconds < 10 ? '0' + seconds : seconds;

    return {
      date: `${month}/${day}/${year}`,
      time: `${hours}:${minutes}:${seconds} ${ampm}`
    };
  };

  const [report , setReport] = useState('');


  const next = async()=>{ 
    console.log('text', tra);
    try {
      setLoading(true)
      setDxCodes([])
      setAllergy("")
      setAssessment("")
      setCptCodes([])
      setIcdCodes([])
      setHPI("")
      setPMH("")
      setPlan("")
      setphysicalExamination("")
      setObjective("")
      setSubjective("")
      setmed("")
      setROS("")
      setchiefComplaint("")
      setSoapNotesSummary("")
      setOriginal("")

      const formData = new FormData();
      formData.append('text', tra);
      formData.append('type', 'text');
      formData.append('practice', 'main-aims');
     
      const response = await axios.post(`${demoURL}/post/generateReportFromAudioFile`, formData);
      console.log(response)
      if(response.data.success === true){
        const codes = response.data.code
        const data = response.data.data
        const listofros = response.data.Ros
        const report = { 
          codes : codes, 
          data:data, 
          listofRos:listofros,
        } 
        localStorage.setItem('Report',JSON.stringify(report)); 
        
       

        if(data.Medications==null || data.Medications=="" )
        {
          setmed("Not discussed during the consultation.")
        }else{
          setmed(data.Medications)
        }
        // ------------------------------------
        if(data.Assessment == null || data.Assessment=="")
        {
          setAssessment("Not discussed during the consultation.");
        }else{
          setAssessment(data.Assessment);
        }
        // ---------------------------------------
        if(codes['ICD-10 Codes'] == null || codes['ICD-10 Codes']  == "" || codes['ICD-10 Codes'] == [] ){
          setIcdCodes([{code:"null",description:"Not discussed during the consultation."}])
        }else{
          setIcdCodes(codes['ICD-10 Codes'])
        }
        if(codes['CPT Codes'] == null || codes['CPT Codes'] == "" || codes['CPT Codes']== []){
          setCptCodes([{code:"null",description:"Not discussed during the consultation."}])
        }else{
          setCptCodes(codes['CPT Codes'])
        }
        if(data.Plan==null || data.Plan=="")
        {
          setPlan("Not discussed during the consultation.");
        }else{
          setPlan(data.Plan);
        }

        if(data.SUMMARY==null || data.SUMMARY=="")
        {
          setSoapNotesSummary("Not discussed during the consultation.");
        }else{
          setSoapNotesSummary(data.SUMMARY);
        }

        if(data.Allergies==null || data.Allergies=="")
        {
          setAllergy("Not discussed during the consultation.");
        }else{
          setAllergy(data.Allergies);
        }

        if(data['History of Present Illness (HPI)']==null || data['History of Present Illness (HPI)']=="")
        {
          setHPI("Not discussed during the consultation.");
        }
        else{
          setHPI(data['History of Present Illness (HPI)']);
        }

        if(data['Past Medical History (PMH)']==null || data['Past Medical History (PMH)']=="")
        {
          setPMH("Not discussed during the consultation.");
        }else{
          setPMH(data['Past Medical History (PMH)']);
        }

       
        let rosData = {
          Constitutional: `${listofros.Constitutional['type']}. ${listofros.Constitutional['description']}` || 'Negative',
          Eyes: `${listofros.Eyes['type']}. ${listofros.Eyes['description']}` || 'Negative',
          ENT: `${listofros.ENT['type']}. ${listofros.ENT['description']}` || 'Negative',
          Cardiovascular:`${listofros.Cardiovascular['type']}. ${listofros.Cardiovascular['description']}` || "Negative",
          Respiratory: `${listofros.Respiratory['type']}. ${listofros.Respiratory['description']}` || 'Negative',
          Gastrointestinal: `${listofros.Gastrointestinal['type']}. ${listofros.Gastrointestinal['description']}` || 'Negative',
          Genitourinary: `${listofros.Genitourinary['type']}. ${listofros.Genitourinary['description']}` || 'Negative',
          Musculoskeletal: `${listofros.Musculoskeletal['type']}. ${listofros.Musculoskeletal['description']}` || 'Negative',
          Skin: `${listofros.Skin['type']}. ${listofros.Skin['description']}` || 'Negative',
          Neurological: `${listofros.Neurological['type']}. ${listofros.Neurological['description']}` || 'Negative',
          Psychiatric: `${listofros.Psychiatric['type']}. ${listofros.Psychiatric['description']}` || 'Negative',
        };
      

        setROS(rosData)

        if(data['Chief Complaint']==null || data['Chief Complaint']=="")
        {
          setchiefComplaint("Not discussed during the consultation.");
        }else{
          setchiefComplaint(data["Chief Complaint"]);
        }

        if(data['Physical Examination']==null || data['Physical Examination']=="")
        {
          setphysicalExamination("Not discussed during the consultation.");
        }
        else{          
          setphysicalExamination(data["Physical Examination"]);
        }

        if(data['Subjective']==null || data['Subjective']=="")
        {
          setSubjective("Not discussed during the consultation.");
        }else{

          setSubjective(data["Subjective"])
        }

        if(data['Objective']==null || data['Objective']=="")
        {
          setObjective("Not discussed during the consultation.");
        }else{
          setObjective(data["Objective"])
        }

        
       
        navigate(`/SOAPnotes/${patientId}?mode=generate`);

        
      
      }else{
        toast.error(response.data.msg)
      }
      } catch (error) {
      toast.error('Failed to generate reeport')

      }finally{
    setLoading(false)
  
    
  }
  }  

  const handleGenerateClick = () => { 
    
    if(!binaryObj){
      showToast('Enter consultation')
      return
    }
    setIsModalOpen(true);
  }; 
  const handleGenerateNote = async (Textt) => { 
    setLoading(true);
    try{
      if(!promptText){
        showToast('Enter system prompt')
        return
      }  
      const data = {
        text: `Doctor Name :${userInfo.first_name} ${userInfo.last_name} Doctor Phono Number : ${userInfo.
phone_number} Doctor Title : ${userInfo.title} Date of Consultation : ${dateNow} Transcript : ${Textt}`,
        prompt:` ${promptText}` ,
        model:selectedModel
      } 
      const res = await axios.post(`${demoURL}/post/testingNewReportMethod`,data)
      setData(res.data.notes);   
      setReport(res.data.notes);
      setIsModalOpen(false); 
      await handleAssistant(res.data.notes);
    }catch(e){

    } 
    finally { 
      setLoading(false); 
    }
  };  
  const handleAssistant = async (notes) => {    
    await handleSendReport(notes);   
 }    
    const [thread , setReportThread] = useState(null); 
 
 const handleSendReport = async (notes) => {
  let idThread; 
 try {

     const response = await axios.get(`${demoURL}/create/thread`); 
     console.log('Assistant Thread Created');
       idThread = response.data.thread.id;  
       localStorage.setItem('ThreadID', response.data.thread.id);
   

   const Data = {
     message: notes, 
     threadId: response.data.thread.id,
   };

   const res = await axios.post(`${demoURL}/create/message`, Data); 
   // if (res.data.response === true) {
   //   const final = await getRun(idThread);
   //   const final2 = await getResponse(idThread, final);

   // }


   // const messageResponse = await axios.get(`${demoURL}/get/messages?threadId=${idThread}`);
   // console.log(messageResponse.data);
 } catch (error) {
   console.error("Error in handleSendReport:", error);
 }
};
 const checkAssistant = async () => {   
   if (data) { 
     setAssistant(true);  
   }
   else if (!data) { 
     alert('No report Generated YET')
   }
 }   
 const convertToDocx = async () => {
  try {
    // Construct the payload for the POST request
    const payload = {
      unstructuredResponse:data, // Include the visitId in the payload
    };

    const result = await axios.post(
      `${Node_API_URL}/api/post/reportDocxDirectDownload`,
      payload, // Pass the payload in the body of the POST request
      {
        responseType: 'blob', // Important: Set response type to blob
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json", // Set appropriate content type
        },
      }
    );

    if (!result.data) {
      alert('Download process failed');
      return;
    }

    const url = window.URL.createObjectURL(new Blob([result.data]));
    const a = document.createElement('a');
    a.href = url;
    a.download = `Report.docx`;
    document.body.appendChild(a);
    a.click();

    // Clean up
    a.remove();
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Error downloading file:', error);
  }
};
    const [assistant , setAssistant] = useState(false);  
 
  const downloadBlobAsMP3 = (blob) => {
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'Consulttation.mp3';
    document.body.appendChild(a);
    a.click();
    URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };

  const mediaRecorderRef = useRef(null); // Reference for MediaRecorder object
  const chunksRef = useRef([]); 
  const [recording, setRecording] = useState(false);
  const [elapsedTime, setElapsedTime] = useState(0);
  const intervalRef = useRef(null);
  const [show,setShow]=useState(false)
  const [binaryObj,setBlob] = useState(null)
  const [chunks, setChunks] = useState([]); 
  useEffect(() => {
    if (recording) {
      intervalRef.current = setInterval(() => {
        setElapsedTime((prevTime) => prevTime + 1);
      }, 1000);
    } else {
      clearInterval(intervalRef.current);
    }
    return () => clearInterval(intervalRef.current);
  }, [recording]);

  
  


  // temporary method 
  const startRecording = async () => {
    try {
      setShowText(false)
      setRecording(true);
      setShow(true) 

      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const mediaRecorder = new MediaRecorder(stream);

      mediaRecorderRef.current = mediaRecorder;
      chunksRef.current = [];
      
      mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          chunksRef.current.push(event.data);
        }
      };

      mediaRecorder.onstop = async() => {
        const allChunks = [...chunks, ...chunksRef.current]; // Combine previous and current chunks
        setChunks(allChunks); 
        let audioBlob;
        if(platform=="IOS")
        {
           audioBlob = new Blob(allChunks, { type: 'audio/mp4' });
        }else{
           audioBlob = new Blob(allChunks, { type: 'audio/webm' });
        }
        setBlob(audioBlob)
      };

      mediaRecorder.start(1000);
      
    } catch (error) {
      console.error('Error starting recording:', error);
      setRecording(false);
      setShow(false)
    }
  }; 
  const reviseReport = async (id) => {  
         
    const Data = { 
     message:`Change the existing Report by Following the Next Prompt and return the updated report full . 
     Prompt:${changeText} `, 
     threadId:id,
    }   
    try {  
    const res = await axios.post(`${demoURL}/create/message`, Data);    
    if(res.data.response == true)  { 
       
       const final = await getRun(id);  
       
       let final2 = await getResponse(id , final);  
       while (final2 != 'completed' && final2 != 'cancelled' && final2 != 'failed' && final2 != 'expired') {  
         final2 = await getResponse(id , final); 
         console.log('final2',final2);
       }  
 const message = await axios.get(`${demoURL}/get/messages?threadId=${id}`);  
  if (message) { 
   localStorage.removeItem('ThreadID');   
   await handleAssistant(message.data.list.body.data[0].content[0].text.value); 
  }
  const body = message.data.list.body; 
   const reply = body.data[0].content[0].text.value;  
   setData(reply); 
   
     }  
   }
  catch(e){ 

  }
}   
const ReportSend = async () => {  
 const response = await axios.get(`${demoURL}/create/thread`);    
 if (response) {
   setReportThread(response.data.thread.id);         
 }  
  const Data = { 
   message:report, 
   threadId:response.data.thread.id,
  }   
  try {  
  const res = await axios.post(`${demoURL}/create/message`, Data);     
   if (res) {
     return Data.threadId
   } 
  }
catch(e){ 

}
}    
   const [edit,setEdit] = useState(false)

const getRun =  async (id) => {  
  const runData = { 
    threadId:id,
    assistantId :'asst_Z7kalJHDtGK1Lt24iVa1buWJ',
  }
 try { 
   const run = await axios.post(`${demoURL}/create/run`,runData)
    if (run.data.response === true) { 
     return run.data.run.id;
    }
 } 
 catch (e) { 

 } 
}  
useEffect(()=> {
 return () => { 
   const thread = localStorage.getItem('ThreadID'); 
   if (thread) {
   localStorage.removeItem('ThreadID');   
   console.log('Thread Removed'); 
   }
 }
},[]) 

 const getResponse = async (threadID , runid) => {   

   const data = { 
     threadId : threadID, 
     runId: runid
   }
   try { 
   const res = await axios.post(`${demoURL}/get/runStatus`,data);  
     if (res.data.response === true ) { 
       return res.data.run.status;
     }  
     else {
     return res 
     }
   } 
   catch (e) { 

   }
 }
  const handleModalClose = () => {
    setIsModalOpen(false);
  };   
  const [updating , setUpdating] = useState(false);
     const handleRevise = async () => {   
      setUpdating(true); 
      try { 
         if (!thread) {
      const id = await ReportSend();  
      await reviseReport(id);   
         } 
         else { 
          await reviseReport(thread);
         }
      } 
      finally{ 
        setUpdating(false); 
        setOpen(false); 
        setchangeText(''); 
      }
  
     }
  const stopRecording = () => {
    if (!recording) return;

    mediaRecorderRef.current.stop();
    setRecording(false);
  };

  const clearRecorderState = () => {
    // mediaRecorderRef.current = null;
    chunksRef.current = [];
    setElapsedTime(0);
    setRecording(false);
    // if (mediaRecorderRef.current) {
    //   // Stop all tracks to release the microphone
    //   mediaRecorderRef.current.getTracks().forEach((track) => track.stop());
    //   mediaRecorderRef.current = null;
    // }
  };

  const toggle = () =>{
    if(recording){
      stopRecording()
    }else{
      startRecording()
    }
  }

  const resetAudio = () =>{
    setShowText(true)
    setShow(false)
    setElapsedTime(0);
    setBlob(null)
    setChunks([])
    clearRecorderState() 
    setSwitch(true);
  } 
 
  const handleOpenAI = async (binaryobj) => {  
    setLoading(true);
    const form = new FormData();  
    form.append('file', binaryobj);   
    try { 
     const res = await axios.post(`${demoURL}/get/transcription`,form)   
     if (res) { 
      setLoading(false);
     }  
     console.log(res);
     if (res.data) { 
       localStorage.setItem('Transcription' , res.data.transcription )   
       setTra(res.data.transcription)

      //  await handleGenerateNote(res.data.transcription);
      } 
    } 
    catch (e) { 

    } 
  } 
  const [trans,setTrans] = useState(true); 
  const [demoText , setDemoText] = useState('Hello');
  return (
    <>
      <div>
        <ToastContainer />
      </div>
      {
      loading && ( 
        <div
        className="fixed inset-0 flex items-center justify-center z-50"
        style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }} 
      >
        <ScreenLoader />
      </div> 
      )}
        <> 
        {!tra && 
          <div className="dark:bg-slate-800 text-white relative"> 
            
            <div className="absolute w-full text-center"></div>
            <div className="flex flex-col items-center dark:bg-slate-800 p-4">
              <div
                className={`w-16 h-16 flex items-center justify-center rounded-full cursor-pointer transition-all duration-300 ${
                  recording ? "bg-red-500 animate-pulse" : "bg-gray-500"
                }`}
                onClick={toggle}
              >
                <svg
                  className="w-8 h-8 text-white"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d={`${
                      recording
                        ? "M4 3a1 1 0 011 1v12a1 1 0 11-2 0V4a1 1 0 011-1zm10 0a1 1 0 011 1v12a1 1 0 11-2 0V4a1 1 0 011-1z"
                        : "M10 2a2 2 0 00-2 2v6a2 2 0 004 0V4a2 2 0 00-2-2zM5 10a1 1 0 011 1v2a4 4 0 008 0v-2a1 1 0 112 0v2a6 6 0 01-12 0v-2a1 1 0 011-1z"
                    }`}
                    clipRule="evenodd"
                  />
                </svg>
              </div>
  
              <br />
              {recording && <Mic />}
              {show && <div className="mt-6">{elapsedTime} seconds</div>}
  
              {showText && (
                <p className="px-32 text-center text-[15px] w-[600px] mt-6"></p>
              )}
  
              <div className="flex flex-wrap justify-center gap-5">
                {/* {binaryObj != null && !recording && (
                  <button
                    onClick={() => next(binaryObj)}
                    className="mt-12 bg-indigo-600 w-36 text-white py-2 px-4 rounded"
                  >
                    Next
                  </button>
                )}  */}
                {binaryObj != null && !recording && (
                  <button
                    onClick={()=>handleOpenAI(binaryObj)}
                    className="mt-12 bg-indigo-600 w-36 text-white py-2 px-4 rounded"
                  >
                    Generate Text
                  </button>
                )}
                {binaryObj != null && !recording && (
                  <button
                    onClick={() => downloadBlobAsMP3(binaryObj)}
                    className="mt-12 w-36 bg-indigo-600 text-white py-2 px-4 rounded"
                  >
                    Download
                  </button>
                )}
                <button
                  onClick={() => toggle()}
                  className="mt-12 w-36 bg-indigo-600 text-white py-2 px-4 rounded"
                >
                  {recording ? "Pause" : binaryObj != null ? "Resume" : "Start"}
                </button>
                {recording && (
                  <button
                    onClick={() => resetAudio()}
                    className="mt-12 w-36 bg-indigo-600 text-white py-2 px-4 rounded"
                  >
                    Reset
                  </button>
                )}
                {!recording && (
                  <button
                    onClick={() => navigate(`/Consultation/${patientId}`)}
                    className="bg-indigo-600 mt-12 w-36 text-white py-2 px-4 rounded"
                  >
                    Back
                  </button>
                )}
              </div> 
  
              <style>
                {`
                  .switch:hover {
                    background-color: rgba(60, 64, 67, 0.8);
                  }
  
                  #checkbox:checked + .switch .mic-off {
                    opacity: 1;
                  }
  
                  #checkbox:checked + .switch .mic-on {
                    opacity: 0;
                  }
  
                  #checkbox:checked + .switch {
                    background-color: #DC2626;
                  }
                `}
              </style>
            </div>
          </div>   
        }
          {tra && ( 
           <ReportGenerator saveReport={saveReport} params={patientId} text={tra} setText={setTra} option1={next} setReportdata={setReportdata} setTrans={setTrans}/> 
           ) }
          {
  data  &&  !assistant &&

      <div className="dark:bg-slate-800 border-[1px] border-grey rounded-lg w-full max-w-5xl p-8 shadow-xl ">
        <div className=" flex justify-between"> 
          <div className="flex gap-4 items-center mb-8 ">  
        <FaEdit onClick={toggle}  size={20} className="text-green-500 cursor-pointer" />
          <span onClick={()=>setOpen(true)} className="bg-green-500 hover:bg-green-600 text-white cursor-pointer p-2  gap-3 flex items-center justify-center h-12 text-center rounded-md"> Revise Report 
          <FiRefreshCw size={15}/> 
            </span> 
          </div>
        <h1 className="text-center text-white text-4xl mb-8 underline font-bold" >Report</h1>  
        <div className="flex gap-4 items-center  mb-8 justify-center"> 
      
        <span onClick={convertToDocx}  className="bg-blue-500 hover:bg-blue-600 text-white cursor-pointer p-3  gap-3 flex items-center justify-center h-12 text-center rounded-md"> Download 
          <FaDownload size={15}/> 
            </span> 
        <MdDeleteForever size={25} className="text-red-500  cursor-pointer" onClick={()=>setData("")}  />
        </div>
        </div>
      {/* method 1 for display  */}
      {
        edit &&
        <textarea
        className="w-full h-96 p-3 bg-slate-800 text-black border border-gray-700 rounded-lg mb-4 focus:outline-none focus:ring-2 focus:ring-blue-500"
        placeholder="Enter your prompt here..."
        value={data}
        onChange={(e) => setData(e.target.value)}
        cols={30}
        ></textarea>
    }
        
      {/* method 2 for display  */}
      {
        !edit && 
      <ReactMarkdown
      className="text-white"
      children={data}
      components={{
        h1: ({ node, ...props }) => (
          <h1 style={{ fontSize: "2rem", marginBottom: "1rem", color: "#FFD700" }} {...props} />
          ),
          h2: ({ node, ...props }) => (
            <h2 style={{ fontSize: "1.5rem", marginBottom: "0.75rem", color: "#FFA500" }} {...props} />
            ),
            h3: ({ node, ...props }) => (
              <h3 style={{ fontSize: "1.25rem", marginBottom: "0.5rem", color: "#FFA07A" }} {...props} />
              ),
              p: ({ node, ...props }) => (
                <p style={{ marginBottom: "1rem", lineHeight: "1.5", color: "#FFFFFF" }} {...props} />
                ),
                ul: ({ node, ...props }) => (
          <ul style={{ marginLeft: "1.5rem", marginBottom: "1rem", color: "#FFFFFF" }} {...props} />
          ),
          li: ({ node, ...props }) => (
            <li style={{ marginBottom: "0.5rem", color: "#FFFFFF" }} {...props} />
            ),
            code({ node, inline, className, children, ...props }) {
              const match = /language-(\w+)/.exec(className || "");
              return !inline && match ? (
                <SyntaxHighlighter
                style={materialLight}
                language={match[1]}
                PreTag="div"
                {...props}
                >
              {String(children).replace(/\n$/, "")}
            </SyntaxHighlighter>
          ) : (
            <code style={{ backgroundColor: "#333", color: "#FFD700", padding: "2px 4px", borderRadius: "4px" }} {...props}>
              {children}
            </code>
          );
        },
      }}
      />
    }


      </div>}
          {isModalOpen && (
        <div className="fixed inset-0  bg-opacity-50 backdrop-blur-sm flex items-center justify-center z-50">
          <div className="bg-[#0f172a] rounded-lg shadow-lg p-6 w-full max-w-md">
            <h2 className="text-xl font-bold text-white mb-4">System  Prompt</h2>
            <textarea
              className="w-full h-32 p-3 bg-slate-800 text-black border border-gray-700 rounded-lg mb-4 focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Enter your prompt here..."
              value={promptText}
              onChange={(e) => setPromptText(e.target.value)}
            ></textarea> 
            <div className="flex justify-end space-x-4">
              <button 
                  className={`px-4 py-2 
                    ${loading ? 'cursor-not-allowed' : 'hover:bg-gray-400'} 
                    bg-gray-300 text-gray-700 rounded-lg`} 
                disabled={loading} 
                onClick={handleModalClose} 
              >
                Cancel
              </button>
              <button
                className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
                onClick={()=>{handleOpenAI(binaryObj)}} 
                disabled={toastActive}
              > 
               {loading ? ( 
              <div className="flex justify-center">
              <Loader/>  
              </div>
            ) : ( 
 
              <p>Generate</p>
            )
            }
                
              </button>
            </div>
          </div>
        </div>
      )}   
     
      {isOpen && (
        <div className="fixed inset-0  bg-opacity-50 backdrop-blur-sm flex items-center justify-center z-50">
          <div className="bg-[#0f172a] rounded-lg shadow-lg p-6 w-full max-w-md">
            <h2 className="text-xl font-bold text-white mb-4">Update  Prompt</h2>
            <textarea
              className="w-full h-32 p-3 bg-slate-800 text-black border border-gray-700 rounded-lg mb-4 focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Enter your prompt here..."
              value={changeText}
              onChange={(e) => setchangeText(e.target.value)}
            ></textarea> 
            <div className="flex justify-end space-x-4">
              <button 
                  className={`px-4 py-2 
                    ${updating ? 'cursor-not-allowed' : 'hover:bg-gray-400'} 
                    bg-gray-300 text-gray-700 rounded-lg`} 
                disabled={updating} 
                onClick={()=>setOpen(false)} 
              >
                Cancel
              </button>
              <button
                className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
                onClick={handleRevise} 
                disabled={toastActive}
              > 
               {updating ? ( 
              <div className="flex justify-center">
              <Loader/>  
              </div>
            ) : ( 
 
              <p>Update</p>
            )
            }
                
              </button>
        
            </div>
          </div>
        </div> 
      )}
        </>
    </>
  );
}  

export default Recordaudio;







