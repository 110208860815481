import React from "react";
import Navbar from '../../Components/Navbar';
import Sidebar from '../../Components/sidebar'; 
import ReportPreview from "../../Components/OpenAiReport/ReportPreview";
const OpenAiReport = (props) => {

  return (
        <div>
      <Navbar name={props.name} />
      <div className='flex'>
      <div className='fixed h-full top-0 left-0 hidden md:block '>
      <Sidebar />
    </div>
        <div className='m-0  md:ml-52  h-full w-full flex-col items-center dark:bg-slate-900'>
           <ReportPreview/>
        </div>
      </div>
    </div>
  );
};

export default OpenAiReport;
