/* eslint-disable */
import React, { useState, useRef, useEffect, useContext } from "react";
import { RiMicLine } from "react-icons/ri";
import ".././styles.css";
import { demoURL } from "../../client.js";
import { ToastContainer, toast } from "react-toastify"; 
import { forwardRef } from "react";
import { useSpring, animated } from "@react-spring/web";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import Pulsating from "../pulsation.js";
import { useNavigate } from "react-router-dom";
import axios from "axios";  
import Loader from "../ui/Loader/Loader.js";
import ReportGenerator from "../OpenAiReport/ReportGenerator.js";
import { GlobalStateContext } from "../../Context/GlobalStateContext.js";
import { useDispatch } from "react-redux";
import { logout } from "../../features/auth/authSlice.js";
import {isIos} from 'environment'; 
import Recordaudio from "./Recordaudio.js";
import { ConnectingAirportsOutlined } from "@mui/icons-material";

const StyledDiv = styled.div`
  align-items: center;
  background: ${({ color }) => color || "#AA0000"};
  border-radius: 32px;
  color: white;
  display: flex;
  height: 32px;
  justify-content: center;
  width: 130px;
  padding: 5px;
`;

function Number({ n }) {
  const { number } = useSpring({
    from: { number: n },
    number: 0,
    delay: 1000,
    config: { mass: 1, tension: 50 },
  });

  return (
    <animated.div className="text-9xl font-extrabold text-blue-800 flex p-20">
      {number.to((n) => n.toFixed(0))}
    </animated.div>
  );
}

const Transcribing =  ({ON, setSwitch ,setReportData}) => {
 
  const {user,setUser,checker,setOriginal,setIcdCodes,setSubjective, setObjective, setmed,setCptCodes, setDxCodes, setAssessment, setPlan, setSoapNotesSummary, setAllergy, setHPI, setPMH, setROS, setchiefComplaint,setphysicalExamination} = useContext(GlobalStateContext)
  

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false); 
  
  const { patientId } = useParams();
  const [affirmation,setAffirmation]=useState("")
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [isRecording, setIsRecording] = useState(true);
  const [showStartButton, setShowStartButton] = useState(true);
  const [showColor, setShowColor] = useState(false);
  const [iscounting, setIscounting] = useState(false);
  const socketRef = useRef(null);
  const navigate = useNavigate();
  const [stop, setStop] = useState(false);
  const textareaRef = useRef(null);
  const [lan,setLang] = useState("en")
  const dispatch = useDispatch()
  const [model,setModel]=useState("nova-medical")
  const [btn,setBtn]=useState(true)  
  const [isPause,setIsPause]=useState(false)
 
  let currentText = '';
  var audioText;
  let socket;
  useEffect(()=>{
    localStorage.removeItem("visit-id")
    checker().then((res)=>{
      if(res==false)
      {
      setUser(false)
      dispatch(logout())
      navigate('/')
      }
    })
    
  },[checker,user])



  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.scrollTop = textareaRef.current.scrollHeight;
    }
  }, [affirmation]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState != 'visible') 
      {
        if (mediaRecorder) 
          {
          setIsRecording(false);
          mediaRecorder.pause();
          } 
      }
    }; 

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);


  const saveReport = async()=>{
    try{
      setLoading(true)      
     const Bodydata= {
            "pId":patientId,
            "reportData":data,
            "transcription":affirmation,
            "mode":"generate"
        }  
      const config = {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    }
      const res  = await axios.post(`${Node_API_URL}/api/post/newReportMethodStoredIntoDb`,Bodydata,config)

      if(res.data.response === true)
      {
        showToast('Report generated')
        navigate(`/Report2.0/${res.data.id}`)

      }
    }catch(e)
    {
        showToast("Error in saving report try again")
    }finally{
      setLoading(false)
    }
}



  const activateMicrophone=async()=>{ 
    console.log('activated'); 
    try {
      if(lan != "en")
      {
        setModel("nova")
      }
      setStop(false);
      setShowStartButton(false);
      setIscounting(false);
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      
      // if (!MediaRecorder.isTypeSupported('audio/webm') || ) {
      //   toast.error('Browser not supported');
        
      //   return;
      // }
      let mediaRecorder;
      if(isIos)
      {
          mediaRecorder = new MediaRecorder(stream, {
          mimeType: 'audio/mp4',
        });
      }else{
         mediaRecorder = new MediaRecorder(stream, {
          mimeType: 'audio/webm',
        });
      }

      setMediaRecorder(mediaRecorder);

      
      console.log(process.env.REACT_APP_DEEPGRAM_API)
      socket = new WebSocket(`wss://api.deepgram.com/v1/listen?Language=${lan}&Model=${model}`, [
        'token',
        '732da4441d661aa735eb59557188a15166f61e24',
      ]);
      socketRef.current = socket;

      socket.onopen = () => {
        mediaRecorder.addEventListener('dataavailable', (event) => {
          if (event.data.size > 0 && socket.readyState === 1) {
            socket.send(event.data);
          }
        });
        mediaRecorder.start(1000);
      };

      socket.onmessage = async (message) => {
        const received = JSON.parse(message.data);
        const transcript = received?.channel?.alternatives[0]?.transcript;
        if (transcript && received.is_final) {
          currentText = currentText.concat(' ' + transcript);
          audioText = currentText;
          setAffirmation(audioText);
        }
      };

      socket.onerror = (error) => {
        console.log(error)
      };

      socket.onclose = () => {
        stream.getTracks().forEach((track) => {
                  if (track.readyState === "live" && track.kind === "audio") {
                    track.stop();
                  }
                });
      };


    } catch (error) {
      console.error('Error',error);
    }

  }




  const handleInputChange = (event) => {
      setAffirmation(event.target.value);
  };


  const sendMessageToBackend = async () => { 
    setLoading(true);
    try {
      setDxCodes([]);
      setAllergy("");
      setAssessment("");
      setCptCodes([]);
      setIcdCodes([]);
      setHPI("");
      setPMH("");
      setPlan("");
      setphysicalExamination("");
      setObjective("");
      setSubjective("");
      setmed("");
      setROS("");
      setchiefComplaint("");
      setSoapNotesSummary("");
      setOriginal("");
  
      if (affirmation.length === 0) {
        toast.error("No conversation");
        setLoading(false);
        return;
      }
  
      const formData = new FormData();
      formData.append('text', affirmation);
      formData.append('type', 'text');
      formData.append('practice', 'main-aims');
      
      const response = await axios.post(`${demoURL}/post/generateReportFromAudioFile`, formData);
      console.log(response)
      if (response.status === 200) {
        const codes = response.data.code;
        const data = response.data.data;
        const listofros = response.data.Ros;
        
        setOriginal(affirmation);
        const report = { 
          codes : codes, 
          data:data, 
          listofRos:listofros,
        } 
        localStorage.setItem('Report',JSON.stringify(report)); 
  
        setmed(data.Medications || "Not discussed during the consultation.");
        setAssessment(data.Assessment || "Not discussed during the consultation.");
        setIcdCodes(codes['ICD-10 Codes'] && codes['ICD-10 Codes'].length > 0 ? codes['ICD-10 Codes'] : [{ code: "null", description: "Not discussed during the consultation." }]);
        setCptCodes(codes['CPT Codes'] && codes['CPT Codes'].length > 0 ? codes['CPT Codes'] : [{ code: "null", description: "Not discussed during the consultation." }]);
        setPlan(data.Plan || "Not discussed during the consultation.");
        setSoapNotesSummary(data.SUMMARY || "Not discussed during the consultation.");
        setAllergy(data.Allergies || "Not discussed during the consultation.");
        setHPI(data['History of Present Illness (HPI)'] || "Not discussed during the consultation.");
        setPMH(data['Past Medical History (PMH)'] || "Not discussed during the consultation.");
        
        const rosData = {
          Constitutional: `${listofros?.Constitutional?.type || 'Negative'}. ${listofros?.Constitutional?.description || ''}`,
          Eyes: `${listofros?.Eyes?.type || 'Negative'}. ${listofros?.Eyes?.description || ''}`,
          ENT: `${listofros?.ENT?.type || 'Negative'}. ${listofros?.ENT?.description || ''}`,
          Cardiovascular: `${listofros?.Cardiovascular?.type || 'Negative'}. ${listofros?.Cardiovascular?.description || ''}`,
          Respiratory: `${listofros?.Respiratory?.type || 'Negative'}. ${listofros?.Respiratory?.description || ''}`,
          Gastrointestinal: `${listofros?.Gastrointestinal?.type || 'Negative'}. ${listofros?.Gastrointestinal?.description || ''}`,
          Genitourinary: `${listofros?.Genitourinary?.type || 'Negative'}. ${listofros?.Genitourinary?.description || ''}`,
          Musculoskeletal: `${listofros?.Musculoskeletal?.type || 'Negative'}. ${listofros?.Musculoskeletal?.description || ''}`,
          Skin: `${listofros?.Skin?.type || 'Negative'}. ${listofros?.Skin?.description || ''}`,
          Neurological: `${listofros?.Neurological?.type || 'Negative'}. ${listofros?.Neurological?.description || ''}`,
          Psychiatric: `${listofros?.Psychiatric?.type || 'Negative'}. ${listofros?.Psychiatric?.description || ''}`,
        };
        
        setROS(rosData);
        setchiefComplaint(data["Chief Complaint"] || "Not discussed during the consultation.");
        setphysicalExamination(data["Physical Examination"] || "Not discussed during the consultation.");
        setSubjective(data["Subjective"] || "Not discussed during the consultation.");
        setObjective(data["Objective"] || "Not discussed during the consultation.");
        
        setLoading(false);
        navigate(`/SOAPnotes/${patientId}?mode=generate`);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  
  

  const redirect = () => {
    socket = null;
    setLoading(true);
    if (socketRef.current) {
      socketRef.current.close();
    }
    if(affirmation.length>50)
    {
      localStorage.setItem('conversation',affirmation)
    }
    sendMessageToBackend();

  }; 
   const [trans,setTrans] = useState(true); 
    const [demoText , setDemoText] = useState('Hello');

  return (
    <>
      <ToastContainer />
      {
      loading && ( 
        <div
        className="fixed inset-0 flex items-center justify-center z-50"
        style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }} 
      >
        <Loader />
      </div> 
      )}
        <div className={`dark:bg-slate-900 sm:w-[100vw] lg:w-[900px]  fade-in-animation p-2  rounded-lg text-white shadow-lg  dark:border-gray-700 `}>  
        <div className="flex gap-2 items-center mt-2 justify-center"> 
                   {btn && ON !=true  && <button
                          className="text-gray-100  fade-in-animation bg-indigo-600 rounded-lg px-5 py-2 text-center text-base mr-2 " 

                          onClick={()=>{
                            setBtn(false)
                            setIsRecording(true)
                            activateMicrophone()  
                            setSwitch(false)
                          }}
                         >
                          Start transcribing
                        </button>} 
                        </div>     
                               
                    {!open && ON !=true && !showStartButton && (
                      <div className="px-8 mt-6 flex justify-center">
                       
                       {ON !=true && ( 
                       <>


                        <button
                          className="text-gray-100 bg-indigo-600 rounded-lg px-5 py-2 text-center text-base mr-2 mb-2" 
                          onClick={()=>{
                            
                              window.location.reload()
                          }}
                         >
                          Start Again
                        </button>  
                        
                        <button
                          className="text-gray-100  bg-green-600 rounded-lg px-5 py-2 text-center text-base mr-2 mb-2" 

                          onClick={()=>{
                            if (mediaRecorder && mediaRecorder.state !== "inactive") {
                              if (isPause) {
                                if (mediaRecorder.state === "paused") {
                                  // Resume recording
                                  mediaRecorder.resume(); 
                                  setSwitch(false);
                                  setIsPause(false); 
                                  console.log("Recording resumed");
                                } else {
                                  console.error("Cannot resume: MediaRecorder is not paused");
                                }
                              } else {
                                if (mediaRecorder.state === "recording") {
                                  // Pause recording
                                  mediaRecorder.pause();
                                  setIsPause(true); 
                                  setSwitch(true);
                                  console.log("Recording paused");
                                } else {
                                  console.error("Cannot pause: MediaRecorder is not recording");
                                }
                              }
                            } else {
                              console.error("MediaRecorder is inactive or not initialized properly");
                            }
                          }}
                         >
                         {isPause ? 'Resume' : 'Pause'}
                        </button>
                        <button
                          className="text-gray-100 bg-red-600 rounded-lg px-5 py-2 text-center text-base mr-2 mb-2" 

                          onClick={()=>{
                            
                              navigate(`/Consultation/${patientId}`)
                          }}
                         >
                          Back
                        </button>
                        
                        </>
                         )}
                       
                      </div>
                    )}
                 
                  <div className="flex items-center justify-center">
                    {iscounting && <Number n={3} />}
                  </div>
                    <div className="mix-h-[10cm] w-full bg-gray-300">  
                    {ON ?  ( 
                      <div className="fade-in-animation">
                      <Recordaudio  setSwitch={setSwitch} setReportdata={setReportData}/>
                    </div>                  
                    ) : 
                    (   
                 <ReportGenerator saveReport={saveReport} params={patientId} text={affirmation} setText={setAffirmation} option1={sendMessageToBackend} setReportdata={setReportData} setTrans={setBtn} demoText={demoText} setopen={setOpen}/> 
                    )                   
                    }  
  </div>
                 
        <style jsx> 
         {` 
      @keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.fade-in-animation {
  animation: fadeIn 0.5s ease-in-out;
}
    
         `
         }
        </style>
        </div>  

           
    </>
  );

}


export default Transcribing;



