import React from 'react';
import { FaFileAlt } from 'react-icons/fa';
import './PhotoUploadButton.css';

const OptionModal = ({ option1, option2, close }) => {
  return (
    <div>
      <div
        className="upload-button"
        onClick={option1}
      >
        <FaFileAlt size={30} color="#ffffff" />
        <span className="upload-text">Upload Documents</span>
      </div>

      <div className="modal-overlay">
        <div className="modal-content">
          <h2>Choose an option</h2>
          <div className="button-container">
            <button className="modal-button" onClick={option1}>
              Default Report 
              <FaFileAlt size={18} />
            </button>
            <button className="modal-button" onClick={option2}>
              Customize Report
              <FaFileAlt size={18} />
            </button>
          </div>
          <button className="close-button" onClick={close} aria-label="Close modal">
            &times;
          </button>
        </div>
      </div>
    </div>
  );
};

export default OptionModal;
