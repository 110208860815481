import axios from 'axios'
import React from 'react'

const teste = () => {

 

  
  return (
    <div >teste</div>
  )
}

export default teste