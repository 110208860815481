import React from 'react'

const ButtonLoader = () => {
  return (
    <div>
      <div className="loader"></div> 
      <style jsx> 
        { 
`
/* From Uiverse.io by Fernando-sv */ 
.loader {
  border: 4px solid rgba(0, 0, 0, .1);
  border-left-color: white;
  border-radius: 50%;
}

.loader {
  border: 4px solid rgba(0, 0, 0, .1);
  border-left-color: white;
  width: 36px;
  height: 36px;
}

.loader {
  border: 4px solid rgba(0, 0, 0, .1);
  border-left-color: white;
  width: 36px;
  height: 36px;
  animation: spin89345 1s linear infinite;
}

@keyframes spin89345 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

`
        }
      </style>
    </div>
  ) 
}

export default ButtonLoader
