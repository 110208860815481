
import React from 'react'
import Signin from"../Components/Authentication/Signin.js"
const signPage = () => {
  return (
    <div >
       <Signin/> 
        </div>
  )
}

export default signPage



 
