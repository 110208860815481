import axios from 'axios';
import React,{ useContext, useEffect, useState } from 'react'
import { FaEdit, FaDownload } from "react-icons/fa";  
import { Node_API_URL } from '../../client';
import { GlobalStateContext } from '../../Context/GlobalStateContext';
import ReactMarkdown from "react-markdown";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { materialLight } from "react-syntax-highlighter/dist/esm/styles/prism";
import { useParams } from 'react-router-dom';
import Loader from '../ui/Loader/Loader';

const ReportPreview = () => {
    const [edit,setEdit] = useState(false)
    const { token , showToast } = useContext(GlobalStateContext)
    const { visitId } = useParams();
    const [loading,setLoading] = useState(false)
    const [transcription,setTranscription] = useState("")
    const [patientId,setPatientId] = useState("")
    const toggle = () => {
        if(edit)
        {
          setEdit(false)
          return
        }
        setEdit(true)
    }

    const convertToDocx = async () => {
        try {
          // Construct the payload for the POST request
          const payload = {
            unstructuredResponse:data, // Include the visitId in the payload
          };
      
          const result = await axios.post(
            `${Node_API_URL}/api/post/reportDocxDirectDownload`,
            payload, // Pass the payload in the body of the POST request
            {
              responseType: 'blob', // Important: Set response type to blob
              headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json", // Set appropriate content type
              },
            }
          );
      
          if (!result.data) {
            alert('Download process failed');
            return;
          }
      
          const url = window.URL.createObjectURL(new Blob([result.data]));
          const a = document.createElement('a');
          a.href = url;
          a.download = `Report.docx`;
          document.body.appendChild(a);
          a.click();
      
          // Clean up
          a.remove();
          window.URL.revokeObjectURL(url);
        } catch (error) {
          console.error('Error downloading file:', error);
        }
    };

    
    const [data,setData] = useState(`
    **SOAP Note**

**Patient Name**: [Redacted for HIPAA compliance]

**Date of Service**: 01/05/2025

**Subjective (S)**

- **Chief Complaint**: Left hip pain rated at 6/10, accompanied by left neck pain, left lower back pain, and left shoulder and elbow pain.
- **History of Present Illness (HPI)**: 
  - The patient was involved in a motor vehicle accident on 11/24/2024.
  - The onset of symptoms began immediately following the accident.
  - Pain is localized to the left side, involving the hip, neck, lower back, shoulder, and elbow.
  - Symptoms have persisted and are described as sharp, tingling, aching, stabbing, and numbness.
  - Radiating pain from the back to the left hip and down the left leg.
  - Additional symptoms include numbness and tingling in the left leg, headaches, sleep disturbances, concentration issues, balance problems, and feelings of depression.
  - Symptoms are constant, occurring 76-100% of the time.
  - Aggravating factors include physical activity and prolonged sitting.
  - Alleviating factors include rest and gentle stretching.
- **Previous Treatments & Responses**: The patient has not previously sought chiropractic or physical therapy treatment. No medications or surgeries reported.
- **Patient Goals**: To reduce pain and numbness, regain full mobility, improve sleep quality, and return to daily activities without discomfort.

**Objective (O)**

- **Vital Signs**: 
  - Blood Pressure: 118/76 mmHg
  - Heart Rate: 72 bpm
- **Inspection/Observation**: 
  - Slightly antalgic gait favoring the left side.
  - Poor posture with forward head carriage.
  - No visible swelling observed.
- **Palpation**: 
  - Tenderness noted in the left paraspinal muscles, left hip, and trapezius.
  - Muscle spasm present in the left lumbar region.
- **ROM Measurements**: 
  - Lumbar flexion at 60° with discomfort.
  - Limited cervical rotation to the left.
- **Special Tests**: 
  - Positive Kemp’s test indicating lumbar spine involvement.
  - Positive Straight Leg Raise (SLR) on the left at 45°.
- **Neurological Exam**: 
  - Reflexes: 2+ bilaterally in lower extremities.
  - Strength: 4/5 in the left lower extremity.
  - Sensation: Decreased sensation in the left leg.

**Procedures Performed Today**: 
- Chiropractic adjustments focused on the lumbar and cervical regions.
- Therapeutic exercises tailored to improve flexibility and strength.
- Manual therapy for muscle spasm relief in the left lumbar area.

**Assessment (A)**

- **Primary Diagnosis (ICD-10 Code)**: M54.5 – Low back pain
- **Secondary Diagnosis (ICD-10 Code)**: M62.830 – Muscle spasm
- **Clinical Impression**: The patient is experiencing musculoskeletal pain and dysfunction following a motor vehicle accident. Initial response to treatment shows potential for improvement with consistent therapy.
- **Prognosis**: Fair, with expected improvement over 6-8 weeks of consistent therapeutic intervention.

**Plan (P)**

- **Treatment Plan**: 
  - Continue chiropractic care twice weekly for 4 weeks.
  - Implement a home exercise program focusing on core strengthening and flexibility.
  - Modalities for pain relief including heat therapy.
- **Short-term Goals**: 
  - Decrease pain levels to 3/10 within 4 weeks.
  - Improve lumbar ROM to 80° flexion.
- **Long-term Goals**: 
  - Achieve full functional mobility and return to regular activities within 8-12 weeks.
  - Resolve numbness in the left leg and improve sleep quality.
- **CPT/Procedure Codes & Units**: 
  - 98940 for chiropractic adjustment (1 unit)
  - 97110 for therapeutic exercises (2 units)
  - 97140 for manual therapy (1 unit)
- **Recommended Follow-up or Referrals**: Follow-up in 1 week to assess response to therapy. Consider referral to a psychologist for depression and anxiety management if symptoms persist.

**Confidentiality Notice**: This document contains confidential information intended for the use of the individual or entity named above. Unauthorized use, disclosure, or distribution is prohibited.
    `) 

    const updateReport = async()=>{
        try{
          setLoading(true)          
          const bodyData={
                "pId":patientId,
                "reportData":data,
                "visitId":visitId,
                "mode":"edit"
            }  

          const config = {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        }
          const res  = await axios.post(`${Node_API_URL}/api/post/newReportMethodStoredIntoDb`,bodyData,config)
          if(res.data.response == true)
          {
            showToast('Report updated')
          }else{
            showToast(res.data.msg)
          }
        }catch(e)
        {
            showToast("Error updating report try again")
        }finally{
          setLoading(false)
        }
    }

    const fetchReportData = async()=>{ 
        try
        {
          setLoading(true)
          const config = {
            headers:{
              "Authorization": `Bearer ${token}`
            }
          }
          const res = await axios.get(`${Node_API_URL}/api/get/viewReport?visitId=${visitId}`,config)
          console.log(res)
          if(res.data.response === true)
          {
            //patient details
            const patient = res.data.patient;
            setPatientId(patient._id)

            //visit details
            const visit = res.data.visit;
            setData(visit.soapNotesSummary)
            setTranscription(visit.all)
          }
        }catch(e)
        {
          console.log("error in fetching report data refresh page please")
        }finally{
          setLoading(false)
        }
    
    }

    useEffect(()=>{
        fetchReportData()
    },[])

  return (
    <div className='flex justify-center items-center'>
    {
        loading ?
        <div className='h-screen flex justify-center items-center' >
         <Loader/>
         </div>
          :(

        <>
    <div className="bg-slate-800 border-[1px] border-grey rounded-lg w-full max-w-5xl p-8 mt-8 shadow-xl ">
        <div className=" flex justify-between"> 
          <div className="flex gap-4 items-center mb-8 ">  
        <FaEdit onClick={toggle}
          size={20} className="text-green-500 cursor-pointer" />
          </div>

        <h1 className="text-center text-white text-4xl mb-8 underline font-bold" >Report</h1>  
        <div className="flex gap-4 items-center  mb-8 justify-center"> 
      
        <span onClick={convertToDocx}  className="bg-blue-500 hover:bg-blue-600 text-white cursor-pointer p-3  gap-3 flex items-center justify-center h-12 text-center rounded-md"> Download 
          <FaDownload size={15}/> 
            </span> 
        </div>
        </div>
      {/* method 1 for display  */}
      {
        edit &&
        <textarea
        className="w-full h-96 p-3 bg-slate-800 text-black border border-gray-700 rounded-lg mb-4 focus:outline-none focus:ring-2 focus:ring-blue-500"
        placeholder="Enter your prompt here..."
        value={data}
        onChange={(e) => setData(e.target.value)}
        cols={100}
        ></textarea>
    }
      
      {/* method 2 for display  */}
      {
        !edit && 
      <ReactMarkdown
      className="text-white"
      children={data}
      components={{
        h1: ({ node, ...props }) => (
          <h1 style={{ fontSize: "2rem", marginBottom: "1rem", color: "#FFD700" }} {...props} />
          ),
          h2: ({ node, ...props }) => (
            <h2 style={{ fontSize: "1.5rem", marginBottom: "0.75rem", color: "#FFA500" }} {...props} />
            ),
            h3: ({ node, ...props }) => (
              <h3 style={{ fontSize: "1.25rem", marginBottom: "0.5rem", color: "#FFA07A" }} {...props} />
              ),
              p: ({ node, ...props }) => (
                <p style={{ marginBottom: "1rem", lineHeight: "1.5", color: "#FFFFFF" }} {...props} />
                ),
                ul: ({ node, ...props }) => (
          <ul style={{ marginLeft: "1.5rem", marginBottom: "1rem", color: "#FFFFFF" }} {...props} />
          ),
          li: ({ node, ...props }) => (
            <li style={{ marginBottom: "0.5rem", color: "#FFFFFF" }} {...props} />
            ),
            code({ node, inline, className, children, ...props }) {
              const match = /language-(\w+)/.exec(className || "");
              return !inline && match ? (
                <SyntaxHighlighter
                style={materialLight}
                language={match[1]}
                PreTag="div"
                {...props}
                >
              {String(children).replace(/\n$/, "")}
            </SyntaxHighlighter>
          ) : (
            <code style={{ backgroundColor: "#333", color: "#FFD700", padding: "2px 4px", borderRadius: "4px" }} {...props}>
              {children}
            </code>
          );
        },
      }}
      />
    }

{
    edit &&
    <div className="flex justify-center mt-6">
    <button
      className="bg-green-500 w-full text-white px-8 py-3 rounded-lg font-semibold hover:bg-green-600 transition-all duration-300 shadow-md"
      onClick={updateReport}
    > 
    <p> Update Report </p>    
    </button>
  </div> 
}

      </div>


  </>
  )
    }
    </div>

  )
}

export default ReportPreview