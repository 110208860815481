/*eslint-disable*/
import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios'; 
import { FaDownload } from "react-icons/fa";
import { FaMusic, FaTrashAlt } from 'react-icons/fa'; // Importing MP3 and Delete icons
import { GlobalStateContext } from '../../Context/GlobalStateContext'; 
import { MdDeleteForever } from "react-icons/md";  
import { useParams } from "react-router-dom"; 
import { ToastContainer, toast } from "react-toastify";   
import { FiRefreshCw } from "react-icons/fi";  
import { Node_API_URL } from '../../client'; 
import ReportGenerator from '../OpenAiReport/ReportGenerator.js';
import ReactMarkdown from "react-markdown";
import { useSelector } from "react-redux";
import { demoURL } from '../../client';    
import { FaEdit } from "react-icons/fa";  
import { SoapNotesPrompt } from "../prompts/soapNotes";
import Loader from '../ui/Loader/Loader.js';
import { useDispatch } from 'react-redux';
import { logout } from '../../features/auth/authSlice'; 
import { useNavigate } from "react-router-dom";

const UploadAudioCompo = ({setReportData}) => {
  const {user,setUser,checker,setOriginal,setIcdCodes,setSubjective, setObjective, setmed,setCptCodes, setDxCodes, setAssessment, setPlan, setSoapNotesSummary, setAllergy, setHPI, setPMH, setROS, setchiefComplaint,setphysicalExamination , token} = useContext(GlobalStateContext)
  const [selectedFile, setSelectedFile] = useState(null); 
  const {showToast , toastActive , userTimezone } = useContext(GlobalStateContext);  
  const [isOpen , setOpen] = useState(false); 
  const { patientId } = useParams(); 
  const [changeText,setchangeText] = useState('');  
  const [updating , setUpdating] = useState(false);
  
  
  const [loading,setLoading]=useState(false)  
  const [data,setData] = useState("")   

  const [report , setReport] = useState('');

  const dispatch = useDispatch()
  const navigate = useNavigate();
    const [edit,setEdit] = useState(false)

    const saveReport = async()=>{
      try{
        setLoading(true)      
       const Bodydata= {
              "pId":patientId,
              "reportData":data,
              "transcription":transcription,
              "mode":"generate"
          }  
        const config = {
          headers: {
              "Authorization": `Bearer ${token}`
          }
      }
        const res  = await axios.post(`${Node_API_URL}/api/post/newReportMethodStoredIntoDb`,Bodydata,config)

        if(res.data.response === true)
        {
          showToast('Report generated')
          navigate(`/Report2.0/${res.data.id}`)
  
        }
      }catch(e)
      {
          showToast("Error in saving report try again")
      }finally{
        setLoading(false)
      }
  }
 
  const convertToDocx = async () => {
    try {
      // Construct the payload for the POST request
      const payload = {
        unstructuredResponse:data, // Include the visitId in the payload
      };
  
      const result = await axios.post(
        `${ Node_API_URL}/api/post/reportDocxDirectDownload`,
        payload, 
        {
          responseType: 'blob', 
          headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json", 
          },
        }
      );
  
      if (!result.data) {
        alert('Download process failed');
        return;
      }
  
      const url = window.URL.createObjectURL(new Blob([result.data]));
      const a = document.createElement('a');
      a.href = url;
      a.download = `Report.docx`;
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };


  const fileUploadLabelStyle = {
    cursor: 'pointer',
    backgroundColor: '#ddd',
    padding: '30px 70px',
    borderRadius: '40px',
    border: '2px dashed rgb(82, 82, 82)',
    boxShadow: '0px 0px 200px -50px rgba(0, 0, 0, 0.719)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '5px',
  };

  const browseButtonStyle = {
    backgroundColor: 'rgb(82, 82, 82)',
    padding: '5px 15px',
    borderRadius: '10px',
    color: 'white',
    transition: 'all 0.3s',
  };

  const browseButtonHoverStyle = {
    backgroundColor: 'rgb(14, 14, 14)',
  };
  const { userInfo } = useSelector((state) => state.auth); 

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file); 
    } else {
      setSelectedFile(null); 
    }
  
    e.target.value = ''; 
  }


  const handleDelete = () => {
    setSelectedFile('');   
    setTranscription('');
  };   
  const [dateNow, setDate] = useState(null);
  
    useEffect(() => {
      const today = new Date();
      const formattedDate = today.toLocaleDateString('en-GB', {
        timeZone: userTimezone,
      });
      setDate(formattedDate);
    }, []);
  const [Loading , setloading] = useState(false); 
  const [transcription,setTranscription] = useState(''); 
  const GetTranscription = async () => {  
    setLoading(true);
    try { 
      const form = new FormData();  
      form.append('file', selectedFile);   
       const text = await axios.post(`${demoURL}/get/transcription`,form);   
       if (text.data.response) { 
        setTranscription(text.data.transcription); 
        setReportData(text.data.transcription);
       }
    }
    catch(e) { 
      console.log(e);
    } 
    finally{ 
      setLoading(false);
    }
  }
  const handleGenerateNote = async () => {  
    setloading(true); 
    try{
      if(!promptText){
        alert('Enter system prompt')
        return
      } 
     
    
       const data = {
        text: `Doctor Name :${userInfo.first_name} ${userInfo.last_name} Doctor Phono Number : ${userInfo.
phone_number} Doctor Title : ${userInfo.title} Date of Consultation : ${dateNow} Transcript : ${transcription}`,
        prompt:` ${promptText}` ,
        model:'gpt-4o'
      } 
      const res = await axios.post(`${demoURL}/post/testingNewReportMethod`,data) 
      console.log(res);
      setData(res.data.notes); 
      setReportData(res.data.notes);  
      setReport(res.data.notes);
      setIsModalOpen(false); 
    }catch(e){
   console.log(e);
    } 
    finally { 
      setloading(false); 
    }
  };  

  useEffect(()=>{
    localStorage.removeItem("visit-id")
    checker().then((res)=>{
      if(res==false)
      {
      setUser(false)
      dispatch(logout())
      navigate('/')
      }
    })
    
  },[checker,user]) 
    const [isModalOpen, setIsModalOpen] = useState(false);  
    const [promptText, setPromptText] = useState(SoapNotesPrompt);
    
  
  const handleModalClose = () => {
    setIsModalOpen(false);
  }; 
  const handleGenerateClick = () => { 
    setIsModalOpen(true);
  };
   
  const next = async()=>{
    try {
      setLoading(true)
      setDxCodes([])
      setAllergy("")
      setAssessment("")
      setCptCodes([])
      setIcdCodes([])
      setHPI("")
      setPMH("")
      setPlan("")
      setphysicalExamination("")
      setObjective("")
      setSubjective("")
      setmed("")
      setROS({})
      setchiefComplaint("")
      setSoapNotesSummary("")
      setOriginal("")

      

      const formData = new FormData();
      formData.append('file', selectedFile);
      formData.append('type', 'upload');
      formData.append('practice', 'main-aims')
      
      const response = await axios.post(`${demoURL}/post/generateReportFromAudioFile`, formData);
      if(response.data.success === true){ 
        const codes = response.data.code
        const data = response.data.data
        const listofros = response.data.Ros 
        const report = { 
          codes : codes, 
          data:data, 
          listofRos:listofros,
        } 
        localStorage.setItem('Report',JSON.stringify(report)); 

        setOriginal(response.data.original)
 
        if(data.Medications==null || data.Medications=="" )
        {
          setmed("Not discussed during the consultation.")
        }else{
          setmed(data.Medications)
        }
        // ------------------------------------
        if(data.Assessment == null || data.Assessment=="")
        {
          setAssessment("Not discussed during the consultation.");
        }else{
          setAssessment(data.Assessment);
        }
        // ---------------------------------------
        if(codes['ICD-10 Codes'] == null || codes['ICD-10 Codes']  == "" || codes['ICD-10 Codes'] == [] ){
          setIcdCodes([{code:"null",description:"Not discussed during the consultation."}])
        }else{
          setIcdCodes(codes['ICD-10 Codes'])
        }
        if(codes['CPT Codes'] == null || codes['CPT Codes'] == "" || codes['CPT Codes']== []){
          setCptCodes([{code:"null",description:"Not discussed during the consultation."}])
        }else{
          setCptCodes(codes['CPT Codes'])
        }
        if(data.Plan==null || data.Plan=="")
        {
          setPlan("Not discussed during the consultation.");
        }else{
          setPlan(data.Plan);
        }
        if(data.SUMMARY==null || data.SUMMARY=="")
        {
          setSoapNotesSummary("Not discussed during the consultation.");
        }else{
          setSoapNotesSummary(data.SUMMARY);
        }
        if(data.Allergies==null || data.Allergies=="")
        {
          setAllergy("Not discussed during the consultation.");
        }else{
          setAllergy(data.Allergies);
        }
        if(data['History of Present Illness (HPI)']==null || data['History of Present Illness (HPI)']=="")
        {
          setHPI("Not discussed during the consultation.");
        }
        else{
          setHPI(data['History of Present Illness (HPI)']);
        }
        if(data['Past Medical History (PMH)']==null || data['Past Medical History (PMH)']=="")
        {
          setPMH("Not discussed during the consultation.");
        }else{
          setPMH(data['Past Medical History (PMH)']);
        }

       
         let rosData = {
          Constitutional: `${listofros.Constitutional['type']}. ${listofros.Constitutional['description']}` || 'Negative',
          Eyes: `${listofros.Eyes['type']}. ${listofros.Eyes['description']}` || 'Negative',
          ENT: `${listofros.ENT['type']}. ${listofros.ENT['description']}` || 'Negative',
          Cardiovascular:`${listofros.Cardiovascular['type']}. ${listofros.Cardiovascular['description']}` || "Negative",
          Respiratory: `${listofros.Respiratory['type']}. ${listofros.Respiratory['description']}` || 'Negative',
          Gastrointestinal: `${listofros.Gastrointestinal['type']}. ${listofros.Gastrointestinal['description']}` || 'Negative',
          Genitourinary: `${listofros.Genitourinary['type']}. ${listofros.Genitourinary['description']}` || 'Negative',
          Musculoskeletal: `${listofros.Musculoskeletal['type']}. ${listofros.Musculoskeletal['description']}` || 'Negative',
          Skin: `${listofros.Skin['type']}. ${listofros.Skin['description']}` || 'Negative',
          Neurological: `${listofros.Neurological['type']}. ${listofros.Neurological['description']}` || 'Negative',
          Psychiatric: `${listofros.Psychiatric['type']}. ${listofros.Psychiatric['description']}` || 'Negative',
        };

        setROS(rosData)
        if(data['Chief Complaint']==null || data['Chief Complaint']=="")
        {
          setchiefComplaint("Not discussed during the consultation.");
        }else{
          setchiefComplaint(data["Chief Complaint"]);
        }
        if(data['Physical Examination']==null || data['Physical Examination']=="")
        {
          setphysicalExamination("Not discussed during the consultation.");
        }
        else{          
          setphysicalExamination(data["Physical Examination"]);
        }
        if(data['Subjective']==null || data['Subjective']=="")
        {
          setSubjective("Not discussed during the consultation.");
        }else{

          setSubjective(data["Subjective"])
        }
        if(data['Objective']==null || data['Objective']=="")
        {
          setObjective("Not discussed during the consultation.");
        }else{
          setObjective(data["Objective"])
        }
        navigate(`/SOAPnotes/${patientId}?mode=generate`);
     
      
      }else{
        toast.error(response.data.msg)
      } 
      } catch (error) {
      toast.error('Failed to generate report')

      }finally{
    setLoading(false)
  }
  }  
  const getRun =  async (id) => {  
    const runData = { 
      threadId:id,
      assistantId :'asst_Z7kalJHDtGK1Lt24iVa1buWJ',
    }
   try { 
     const run = await axios.post(`${demoURL}/create/run`,runData)
      if (run.data.response === true) { 
       return run.data.run.id;
      }
   } 
   catch (e) { 

   } 
  }  
  const getResponse = async (threadID , runid) => {   

    const data = { 
      threadId : threadID, 
      runId: runid
    }
    try { 
    const res = await axios.post(`${demoURL}/get/runStatus`,data);  
      if (res.data.response === true ) { 
        return res.data.run.status;
      }  
      else {
      return res 
      }
    } 
    catch (e) { 

    }
  }
 
  const reviseReport = async (id) => {  
         
    const Data = { 
     message:`Change the existing Report by Following the Next Prompt and return the updated report full . 
     Prompt:${changeText} `, 
     threadId:id,
    }   
    try {  
    const res = await axios.post(`${demoURL}/create/message`, Data);    
    if(res.data.response == true)  { 
       
       const final = await getRun(id);  
       
       let final2 = await getResponse(id , final);  
       while (final2 != 'completed' && final2 != 'cancelled' && final2 != 'failed' && final2 != 'expired') {  
         final2 = await getResponse(id , final); 
         console.log('final2',final2);
       }  
 const message = await axios.get(`${demoURL}/get/messages?threadId=${id}`);  
  if (message) { 
   localStorage.removeItem('ThreadID');   
  }
  const body = message.data.list.body; 
   const reply = body.data[0].content[0].text.value;  
   setData(reply); 
   
     }  
   }
  catch(e){ 
   console.log(e);
  }
}   
const ReportSend = async () => {  
 const response = await axios.get(`${demoURL}/create/thread`);    
 if (response) {
   setReportThread(response.data.thread.id);         
 }  
  const Data = { 
   message:report, 
   threadId:response.data.thread.id,
  }   
  try {  
  const res = await axios.post(`${demoURL}/create/message`, Data);     
   if (res) {
     return Data.threadId
   } 
  }
catch(e){ 
  console.log(e);
}
}  

  const toggle = () =>{
    if(edit)
    {
      setEdit(false)
      return
    }
    setEdit(true)
  }  
    const [thread , setReportThread] = useState(null); 
  
  const handleRevise = async () => {   
    setUpdating(true); 
    try { 
       if (!thread) {
    const id = await ReportSend();  
    await reviseReport(id);   
       } 
       else { 
        await reviseReport(thread);
       }
    } 
    finally{ 
      setUpdating(false); 
      setOpen(false); 
      setchangeText(''); 
    }

   }

  const handleOpenAI = async () => {  
    setLoading(true);
    const form = new FormData();  
    form.append('file', selectedFile);   
    try { 
     const res = await axios.post(`${demoURL}/get/transcription`,form)  
     if (res) { 
      setLoading(false);
     } 
     if (res.data.response === true) { 
       localStorage.setItem('Transcription' , res.data.transcription ) 
       navigate(`/OpenAiReport/${patientId}`)
      }
    } 
    catch (e) { 

    }  
  }  
  

  return (
    <>
 <ToastContainer />
 {isModalOpen && (
        <div className="fixed inset-0  bg-opacity-50 backdrop-blur-sm flex items-center justify-center z-50">
          <div className="bg-[#0f172a] rounded-lg shadow-lg p-6 w-full max-w-md">
            <h2 className="text-xl font-bold text-white mb-4">System  Prompt</h2>
            <textarea
              className="w-full h-32 p-3 bg-slate-800 text-black border border-gray-700 rounded-lg mb-4 focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Enter your prompt here..."
              value={promptText}
              onChange={(e) => setPromptText(e.target.value)}
            ></textarea> 
            <div className="flex justify-end space-x-4">
              <button 
                  className={`px-4 py-2 
                    ${loading ? 'cursor-not-allowed' : 'hover:bg-gray-400'} 
                    bg-gray-300 text-gray-700 rounded-lg`} 
                disabled={loading} 
                onClick={handleModalClose} 
              >
                Cancel
              </button>
              <button
                className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
                onClick={handleGenerateNote} 
                disabled={toastActive}
              > 
               {loading ? ( 
              <div className="flex justify-center">
              <Loader/>  
              </div>
            ) : ( 
 
              <p>Generate</p>
            )
            }
                
              </button>
            </div>
          </div>
        </div>
      )} 
 {loading && (
  <div
    className="fixed inset-0 flex items-center justify-center z-50"
    style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)' }} 
  >
    <Loader />
  </div>
)}
      <div className="flex flex-col w-full  justify-center items-center">
       <h1 className='text-white mb-4 font-semibold text-2xl'> Choose The Audio File </h1>
        <div >
        <label htmlFor="file" style={fileUploadLabelStyle}>
  <div className="file-upload-design flex mt-4 flex-col">
    {selectedFile ? (
      <>
        <FaMusic size={40} color="green" className="mx-auto" />
        <p>{selectedFile.name}</p>
      </>
    ) : (
      <>
        <svg className="mx-auto" viewBox="0 0 660 532" height="4em">
          <path
            d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-217c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l39-39V392c0 13.3 10.7 24 24 24s24-10.7 24-24V257.9l39 39c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-80-80c-9.4-9.4-24.6-9.4-33.9 0l-80 80z"
          ></path>
        </svg>
        <p>Select Audio File</p>
        <span
          className="browse-button"
          style={browseButtonStyle}
          onMouseEnter={(e) =>
            (e.target.style.backgroundColor =
              browseButtonHoverStyle.backgroundColor)
          }
          onMouseLeave={(e) =>
            (e.target.style.backgroundColor =
              browseButtonStyle.backgroundColor)
          }
        >
          Browse file
        </span>
      </>
    )}
  </div>
  <input
    id="file"
    type="file"
    style={{ display: "none" }}
    accept="audio/mp3"
    onChange={handleFileChange}
  />
</label>

        </div>     
        
      
{
  data  &&

      <div className="bg-slate-800 border-[1px] border-grey rounded-lg w-full max-w-5xl p-8 mt-8 shadow-xl ">
        <div className=" flex justify-between"> 
          <div className="flex gap-4 items-center mb-8 ">  
        <FaEdit onClick={toggle}  size={20} className="text-green-500 cursor-pointer" />
          <span onClick={()=>setOpen(true)} className="bg-green-500 hover:bg-green-600 text-white cursor-pointer p-2  gap-3 flex items-center justify-center h-12 text-center rounded-md"> Revise Report 
          <FiRefreshCw size={15}/> 
            </span> 
          </div>
        <h1 className="text-center text-white text-4xl mb-8 underline font-bold" >Report</h1>  
        <div className="flex gap-4 items-center  mb-8 justify-center"> 
      
        <span onClick={convertToDocx}  className="bg-blue-500 hover:bg-blue-600 text-white cursor-pointer p-3  gap-3 flex items-center justify-center h-12 text-center rounded-md"> Download 
          <FaDownload size={15}/> 
            </span> 
        <MdDeleteForever size={25} className="text-red-500  cursor-pointer" onClick={()=>{setData("");setReportData('')}}  />
        </div>
        </div>
      {/* method 1 for display  */}
      {
        edit &&
        <textarea
        className="w-full h-96 p-3 bg-slate-800 text-black border border-gray-700 rounded-lg mb-4 focus:outline-none focus:ring-2 focus:ring-blue-500"
        placeholder="Enter your prompt here..."
        value={data}
        onChange={(e) => setData(e.target.value)}
        cols={30}
        ></textarea>
    }
      
      {/* method 2 for display  */}
      {
        !edit && 
      <ReactMarkdown
      className="text-white"
      children={data}
      components={{
        h1: ({ node, ...props }) => (
          <h1 style={{ fontSize: "2rem", marginBottom: "1rem", color: "#FFD700" }} {...props} />
          ),
          h2: ({ node, ...props }) => (
            <h2 style={{ fontSize: "1.5rem", marginBottom: "0.75rem", color: "#FFA500" }} {...props} />
            ),
            h3: ({ node, ...props }) => (
              <h3 style={{ fontSize: "1.25rem", marginBottom: "0.5rem", color: "#FFA07A" }} {...props} />
              ),
              p: ({ node, ...props }) => (
                <p style={{ marginBottom: "1rem", lineHeight: "1.5", color: "#FFFFFF" }} {...props} />
                ),
                ul: ({ node, ...props }) => (
          <ul style={{ marginLeft: "1.5rem", marginBottom: "1rem", color: "#FFFFFF" }} {...props} />
          ),
          li: ({ node, ...props }) => (
            <li style={{ marginBottom: "0.5rem", color: "#FFFFFF" }} {...props} />
            ),
            code({ node, inline, className, children, ...props }) {
              const match = /language-(\w+)/.exec(className || "");
              return !inline && match ? (
                <SyntaxHighlighter
                style={materialLight}
                language={match[1]}
                PreTag="div"
                {...props}
                >
              {String(children).replace(/\n$/, "")}
            </SyntaxHighlighter>
          ) : (
            <code style={{ backgroundColor: "#333", color: "#FFD700", padding: "2px 4px", borderRadius: "4px" }} {...props}>
              {children}
            </code>
          );
        },
      }}
      />
    }


      </div>}
      

      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0  bg-opacity-50 backdrop-blur-sm flex items-center justify-center z-50">
          <div className="bg-[#0f172a] rounded-lg shadow-lg p-6 w-full max-w-md">
            <h2 className="text-xl font-bold text-white mb-4">System  Prompt</h2>
            <textarea
              className="w-full h-32 p-3 bg-slate-800 text-black border border-gray-700 rounded-lg mb-4 focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Enter your prompt here..."
              value={promptText}
              onChange={(e) => setPromptText(e.target.value)}
            ></textarea> 
            <div className="flex justify-end space-x-4">
              <button 
                  className={`px-4 py-2 
                    ${loading ? 'cursor-not-allowed' : 'hover:bg-gray-400'} 
                    bg-gray-300 text-gray-700 rounded-lg`} 
                disabled={loading} 
                onClick={handleModalClose} 
              >
                Cancel
              </button>
              <button
                className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
                onClick={handleGenerateNote} 
                disabled={toastActive}
              > 
               {Loading ? ( 
              <div className="flex justify-center"> 
              
              <Loader/>  
              </div>
            ) : ( 
 
              <p>Generate</p>
            )
            }
                
              </button>
            </div>
          </div>
        </div>
      )} 
      {isOpen && (
        <div className="fixed inset-0  bg-opacity-50 backdrop-blur-sm flex items-center justify-center z-50">
          <div className="bg-[#0f172a] rounded-lg shadow-lg p-6 w-full max-w-md">
            <h2 className="text-xl font-bold text-white mb-4">Update  Prompt</h2>
            <textarea
              className="w-full h-32 p-3 bg-slate-800 text-black border border-gray-700 rounded-lg mb-4 focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Enter your prompt here..."
              value={changeText}
              onChange={(e) => setchangeText(e.target.value)}
            ></textarea> 
            <div className="flex justify-end space-x-4">
              <button 
                  className={`px-4 py-2 
                    ${updating ? 'cursor-not-allowed' : 'hover:bg-gray-400'} 
                    bg-gray-300 text-gray-700 rounded-lg`} 
                disabled={updating} 
                onClick={()=>setOpen(false)} 
              >
                Cancel
              </button>
              <button
                className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
                onClick={handleRevise} 
                disabled={toastActive}
              > 
               {updating ? ( 
              <div className="flex justify-center">
              <Loader/>  
              </div>
            ) : ( 
 
              <p>Update</p>
            )
            }
                
              </button>
            </div>
          </div>
        </div>
      )}
        <div className="flex gap-4 mt-4">
                   
        <button onClick={() => navigate(`/Consultation/${patientId}`)} className="mt-12 bg-indigo-600 w-36 text-white py-2 px-4 rounded">Back</button> 
        {selectedFile && selectedFile.type === 'audio/mpeg'  && ( 
                        <>
                     
                    <button 
                      className='mt-12 bg-green-600 w-36 text-white py-2 px-4 rounded' 
                      onClick={GetTranscription}
                    >
                      Generate Text
                    </button>   
  

                    <div  
                     onClick={handleDelete}
                      className='mt-12 bg-red-600 cursor-pointer  flex gap-1 justify-center items-center w-36 text-white py-2 px-4 rounded' 
                      >  
                      Delete
                    <FaTrashAlt
                      size={15}
                      color="white"
                      style={{ cursor: 'pointer', margin:"5px" }}
                    />   
                    </div>
                    </>
                    )}
 
                  </div>
      </div>  
      {transcription &&  
      (
      <div className= 'sm:w-[100vw] lg:w-[900px]'>
      <ReportGenerator saveReport={saveReport} params={patientId} text={transcription} setText={setTranscription} option1={next} setReportdata={setReportData}/> 
        </div>
      )}
        

    </>
  );
};

export default UploadAudioCompo;
