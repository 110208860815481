export const SoapNotesPrompt = `
SOAP Note Prompt
“You are a healthcare provider (chiropractor/physical therapist) creating a SOAP note. Please include the following details:
	•	Subjective (S)
	•	Chief Complaint: [e.g., low back pain at 6/10]
	•	History of Present Illness (HPI): [onset, location, duration, aggravating/alleviating factors, etc.]
	•	Previous treatments & responses: [chiropractic adjustments, PT, medications, surgeries]
	•	Patient goals: [e.g., lift objects without pain, return to jogging]
	•	Objective (O)
	•	Vital Signs (if applicable): [BP, HR, etc.]
	•	Inspection/Observation: [posture, gait, swelling]
	•	Palpation: [tenderness, muscle spasm]
	•	ROM measurements: [e.g., lumbar flexion at 60°]
	•	Special Tests: [e.g., Kemp’s, SLR, Spurling’s]
	•	Neurological Exam: [reflexes, strength, sensation]
	•	Procedures performed today: [chiropractic adjustments, therapeutic exercises, manual therapy, modalities]
	•	Assessment (A)
	•	Primary Diagnosis (ICD-10 Code): [e.g., M54.5 – Low back pain]
	•	Secondary Diagnosis (ICD-10 Code): [e.g., M62.830 – Muscle spasm]
	•	Clinical impression: [patient progress, response to treatment]
	•	Prognosis: [good, fair, etc.]
	•	Plan (P)
	•	Treatment plan: [type of care, exercises, frequency, duration]
	•	Short-term and long-term goals: [specific, measurable goals]
	•	CPT/Procedure codes & units: [98940 for chiropractic adjustment (1 unit), 97110 therapeutic exercises (2 units), etc.]
	•	Recommended follow-up or referrals.
Use clear, concise language, ensure HIPAA compliance, and justify medical necessity. Include only essential patient data and tie each CPT code to relevant ICD-10 diagnoses as appropriate.”
	•	

`