import React  from 'react'; 
import { useNavigate } from 'react-router-dom';

const ModalTable = ({isOpen , setIsOpen , data}) => {

  const toggleModal = () => setIsOpen(!isOpen);

  const modalStyles = {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.75)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 1000,
    },
    modal: {
      backgroundColor: '#0f172a', // slate-900
      borderRadius: '8px',
      padding: '24px',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      maxWidth: '800px',
      width: '90%',
    },
    table: {
      width: '100%',
      borderCollapse: 'separate',
      borderSpacing: '0 8px',
    },
    th: {
      textAlign: 'left',
      padding: '12px 16px',
      backgroundColor: '#1e293b', // slate-800
      color: '#e2e8f0', // slate-200
      fontWeight: 'bold',
      textTransform: 'uppercase',
      fontSize: '14px',
      letterSpacing: '0.05em',
    },
    td: {
      padding: '12px 16px',
      backgroundColor: '#1f2937', // slate-800
      color: '#e2e8f0', // slate-200
    },
    button: {
      backgroundColor: '#3b82f6', // blue-500
      color: 'white',
      padding: '8px 16px',
      borderRadius: '4px',
      border: 'none',
      cursor: 'pointer',
      fontSize: '16px',
      transition: 'background-color 0.3s',
    },
  }; 
  const navigate = useNavigate();
  const navigateToPatientPage = (patientID) => { 
    navigate(`/PatientFich/${patientID}`)
  }

  return (
    <div>
      {isOpen && (
        <div style={modalStyles.overlay} onClick={toggleModal}>
          <div style={modalStyles.modal} onClick={(e) => e.stopPropagation()}>
            <h2 style={{ color: '#e2e8f0', marginBottom: '16px', fontSize: '24px' }}>Invoices</h2>
            <table style={modalStyles.table} >
              <thead>
                <tr>
                  <th style={modalStyles.th}>Date</th>
                  <th style={modalStyles.th}>Status</th>
                  <th style={modalStyles.th}>Amount</th>
                </tr>
              </thead>
              <tbody>
                {data.map((row, index) => (
                  <tr className='cursor-pointer' onClick={()=>{navigateToPatientPage(row.pId)}} key={index}> 
                  
                    <td style={modalStyles.td}>{row.date}</td>
                    <td style={modalStyles.td}>
                      <span style={{
                        padding: '4px 8px',
                        borderRadius: '4px',
                        backgroundColor: row.status === 'Paid' ? '#10b981' : '#ef4444',
                        color: 'white',
                        fontSize: '12px',
                        fontWeight: 'bold',
                      }}>
                        {row.status}
                      </span>
                    </td>
                    <td style={modalStyles.td}>${row.subTotal}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <button 
              onClick={toggleModal} 
              style={{
                ...modalStyles.button,
                backgroundColor: '#ef4444', // red-500
                marginTop: '16px',
              }}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ModalTable;

